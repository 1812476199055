
<div class="list-header">
  <div shipment-queue-filter-need-appointment></div>
</div>

<div nz-row>
  <div nz-col [nzSpan]="8">
    <div scrollTracker [isScrollTop]="true" (scrollingFinished)="getDataMore()" class="list-order-dynamic-height">
      <nz-table
        #nzTable nzBordered="true"
        [nzData]="listData" nzSize="small"
        [nzTotal]="totalCount" [nzFrontPagination]="false"
        [nzShowPagination]="false"
        [nzPageSize]="limit" [nzPageIndex]="pageIndex"
        [nzLoading]="isLoading"
        [nzLoadingIndicator]="tplLoading"
        [nzNoResult]="tplNoData"
        [nzSize]="'default'"
      >
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-template>
        <tbody>
          <tr *ngFor="let item of listData; let i = index">
            <td class="box-info" [id]="item.id">
              <div class="top-container">
                <div class="order-and-customer">
                  <a [routerLink]="[routeAdminShipmentList, item?.orderId]" target="_blank">{{showShipmentCode(item)}} </a>
                  - {{item?.client?.name}}
                </div>
              </div>
              <ng-container *ngFor="let location of item?.deliveryInfos" >
                <div 
                  *ngIf="shouldShowLocation(location, item)"
                  class="location-item" 
                  [id]="location.id" 
                  (click)="viewScheduleAppointment(item.id, location.id)"
                >
                  <div nz-row>
                    <div nz-col [nzSpan]="16">
                      <div class="label">
                        <img style="margin-right: 3px;" src="assets/img/shipment-queue/delivery-icon.svg" /> 
                        {{location.type}} <span *ngIf="location.locationName">({{location.locationName}})</span>
                      </div>

                      <div class="content">
                        <span nz-icon nzType="environment" nzTheme="outline"></span>
                        {{getAddressText(location?.addr)}}
                      </div>

                      <div *ngIf="location?.appointmentInfo?.from" class="content">
                        <span nz-icon nzType="field-time" nzTheme="outline"></span>
                        Appointment: {{displayWindows(location.appointmentInfo, location) || 'N/A'}}
                      </div>    

                      <div *ngIf="!location?.appointmentInfo?.from">
                        <div *ngIf="!location?.windows?.length" class="content">
                          <span nz-icon nzType="field-time" nzTheme="outline"></span>
                          Time Window: N/A
                        </div>
                        <div *ngFor="let window of location?.windows || []" class="content">
                          <span nz-icon nzType="field-time" nzTheme="outline"></span>
                          Time Window: {{displayWindows(window, location) || 'N/A'}}
                        </div>
                        <div *ngIf="location?.note" class="content">
                          <i nz-icon nzType="edit" nzTheme="outline"></i>
                          Note: {{location.note}}
                        </div>
                      </div>

                    </div>
        
                    <div nz-col [nzSpan]="7">
                      <div *ngIf="shouldWarnAppt(location)" class="appointment-tag appointment-needed">
                        Appointment Needed    
                      </div>
                      <div *ngIf="isAwaitingResponse(location)" class="appointment-tag appointment-awaiting">
                        Awaiting Response   
                      </div>
                      <div *ngIf="isWindowsScheduled(location)" class="appointment-tag appointment-scheduled">
                        Scheduled
                      </div>
                      <div *ngIf="isApptScheduled(location)" class="appointment-tag appointment-scheduled">
                        Appointment Scheduled
                      </div>
                      <div *ngIf="isNoScheduleNeeded(location)" class="appointment-tag appointment-scheduled">
                        No Schedule Needed
                      </div>
                      <!-- <div *ngIf="isApptEmpty(location)" class="appointment-tag appointment-empty">
                        No Appointment
                      </div> -->
                      <div *ngIf="isNeedUpdateWindows(location)" class="appointment-tag appointment-needed">
                        No Time Window
                      </div>
                      <div *ngIf="location?.appointmentInfo?.contactType" class="appointment-type">
                        <img [src]="getApptContactType(location)?.icon" />
                          {{getApptContactType(location)?.text}}
                      </div>
                      <div *ngIf="!location.skipAppointment && location.requiresAppointment" class="appointment-type">
                        <label nz-checkbox (nzCheckedChange)="noScheduleClick(item.id, location.id, $event)">Skip</label> 
                      </div>
                    </div>

                    <div nz-col [nzSpan]="1">
                      <img class="arrow-right" src="assets/img/shipment-queue/arrow-right-icon.svg" />
                    </div>
                  </div>
                </div>
              </ng-container>
            </td>
          </tr>
          <ng-container *ngIf="isLoading && listData?.length">
            <tr>
              <td>
                <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="listData?.length == totalCount && totalCount > 0">
            <tr>
              <td>
                <div style="display: flex;justify-content: center; margin-top: 10px;">
                  <img class="img-notify-dispatch" src="assets/img/notifi-dispatch.png" width="50px" alt="">
                </div>
                <div style="text-align: center; margin-top: 10px;font-size: 13px;font-weight: 300;">
                  Total: {{totalCount}} shipments.
                  <br />That's all shipments need appointment
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
  <div nz-col [nzSpan]="16">
    <div schedule-appointments 
      [data]="{shipmentId: this.currentOrderId, stopId: this.currentLocationId}" 
      (onRefresh)="onRefresh()"
    >
    </div>      
  </div>
</div>