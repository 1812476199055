import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FilterLayout, FilterFieldDeclaration } from "@app/admin/components/filter-layout";
import { DateUtil } from "@services/date-utils";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: '[shipment-queue-filter-need-appointment]',
  templateUrl: './need-appointment.html',
  styleUrls: ['./style.scss']
})
export class ShipmentQueueFilterNeedAppointment implements OnInit, AfterViewInit {
  warpIdInputChange = new Subject<any>();
  private warpIdInputChangeSub;
  constructor() {
    this.warpIdInputChangeSub = this.warpIdInputChange
      .pipe(debounceTime(200))
      .subscribe((value) => {
        this.filter.onChangeWithWarpIdString(value);
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;
  @ViewChild('tplFilter_shipmentWarpId') tplFilter_shipmentWarpId: TemplateRef<any>;
  @ViewChild('tplFilter_customer') tplFilter_customer: TemplateRef<any>;
  @ViewChild('tplFilter_type_customer') tplFilter_type_customer: TemplateRef<any>;
  @ViewChild('tplFilter_delivery_time') tplFilter_delivery_time: TemplateRef<any>;

  onFilterChange(key: string, value: any) {
    if (key === 'warpId')
      this.warpIdInputChange.next({key, value});
    else
      this.filter.onChangeWithWarpIdString({key, value});
  }

  getFilterFields(): FilterFieldDeclaration[] {
    return [
      {
        key: 'warpId',
        declaration: { label: 'WARP ID', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_shipmentWarpId
      },
      {
        key: 'clientId',
        declaration: { label: 'Customer', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_customer
      },
      {
        key: 'clientFilterType',
        declaration: { label: '', placeHolder: '', notAcceptEmpty: true, initialValue: 'include' },
        templateInput: this.tplFilter_type_customer
      },
      {
        key: 'code',
        declaration: { label: 'WARP ID', placeHolder: '', notAcceptEmpty: true, hidden: true },
        templateInput: null
      },
      {
        key: 'pickFrom',
        declaration: { label: 'Pickup Date Range', placeHolder: 'From date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
        templateInput: this.tplFilter_delivery_time
      },
      {
        key: 'pickTo',
        declaration: { label: '', placeHolder: 'To date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
        templateInput: this.tplFilter_delivery_time
      },
      {
        key: 'dropFrom',
        declaration: { label: 'Delivery Date Range', placeHolder: 'From date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
        templateInput: this.tplFilter_delivery_time
      },
      {
        key: 'dropTo',
        declaration: { label: '', placeHolder: 'To date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
        templateInput: this.tplFilter_delivery_time
      },
    ]
  }

  getPlaceholder(key: string) {
    return this.filter.getPlaceHolder(key);
  }

  ngOnDestroy() {
    if (this.warpIdInputChangeSub) {
      this.warpIdInputChangeSub.unsubscribe();
    }
  }
}
