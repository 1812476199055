<div #filter filter-layout></div>

<ng-template #tplFilter_shipmentWarpId let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <nz-input-group>
      <input
        (ngModelChange)="onFilterChange(key, $event)"
        [formControlName]="key"
        nz-input
      >
    </nz-input-group>
  </ng-container>
</ng-template>
<ng-template #tplFilter_customer let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div style="min-width: 200px;">
      <select-by-searching
        (modelChange)="onFilterChange(key, $event)"
        [formControlName]="key"
        [placeholder]="'Select'" [version]="2"
        dropdownClassName="filter-customer" nzMode="multiple">
      </select-by-searching>
    </div>
  </ng-container>
</ng-template>
<ng-template #tplFilter_type_customer let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div style="min-width: 100px;">
      <nz-select [formControlName]="key"  (ngModelChange)="onFilterChange(key, $event)" style="width: 100%">
        <nz-option nzLabel="Include" nzValue="include"></nz-option>
        <nz-option nzLabel="Exclude" nzValue="exclude"></nz-option>
      </nz-select>
    </div>
  </ng-container>
</ng-template>
<ng-template #tplFilter_delivery_time let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div style="min-width: 100px;">
      <nz-date-picker [formControlName]="key" (ngModelChange)="onFilterChange(key, $event)" [nzPlaceHolder]="getPlaceholder(key)" style="width: 100%;"></nz-date-picker>
    </div>
  </ng-container>
</ng-template>

